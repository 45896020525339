@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*>* {
	font-family: "Poppins", cursive;
}

body {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: space-around;
	align-items: stretch;
	background-color: var(--color-dark);
	color: var(--color-light);
	background: radial-gradient(ellipse farthest-corner at center top, #ff965685 0%, #b96e2c 100%);
}
:root {
	--color-dark: #2C3131;
	--color-light: #F3F3F2;
}
#main {
	padding: 20px;
}
h1 {
	text-align: center;
}
.logo {
	width: 25px;
	height: 25px;
	position: relative;
	top: 13px;
	left: 20px;
	float: left;
	text-align: center;
	background-repeat: no-repeat, no-repeat;
	background-image: url("../icon-animate.gif");
}
.board {
	flex-direction: column;
}
.board .leaderboard {
	margin-bottom: 1em;
}
.board .duration {
	display: flex;
	justify-content: center;
	gap: 1em;
}
.board .duration>button {
	border: 1px solid var(--color-light);
	color: var(--color-light);
	padding: .5em 2em;
	border-radius: 50px;
	background-color: transparent;
	cursor: pointer;
}
.board .duration>button:hover {
	background-color: var(--color-light);
	color: var(--color-dark);
}
.active {
	background-color: var(--color-light);
	color: var(--color-dark);
}
#profile {
	background-color: #3a404d;
	border-radius: 10px;
	box-shadow: 0 7px 30px rgba(62, 9, 11, .3);
	margin-top: 2em;
	flex-direction: column;
	flex-basis: 100px;
	flex-grow: 0;
	flex-shrink: 0;
}
#profile .panel {
	display: flex;
	justify-content: space-between;
	gap: 1em;
	text-align: left;
	margin-bottom: 1em;
}
#profile .panel .item {
	display: flex;
	align-items: center;
}
#profile .panel .info {
	padding: 0.5em;
	text-align: left;
}
#profile .panel {
	position: relative;
	z-index: 1;
	font-size: 14px;
	padding: 18px 50px 18px 50px;
	/* cursor: pointer; */
	color: var(--color-light);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: translateZ(0) scale(1, 1);
}
#profile .panel::before {
	position: absolute;
	z-index: 2;
}
#profile .panel::after {
	content: "";
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #3a404d;
	color: var(--color-light);
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.08);
	transition: all 0.3s ease-in-out;
	opacity: 0;
}
#profile .panel:hover {
	z-index: 2;
	overflow: visible;
}
.text-dark {
	color: var(--color-light);
}
.leaderboard {
	background: linear-gradient(to bottom, #3a404d, #3c4251);
	border-radius: 10px;
	padding: 20px;
	box-shadow: 0 7px 30px rgba(62, 9, 11, .3);
}
.pagination-controls {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2em;
}
.pagination-controls button {
	border: 1px solid var(--color-light);
	color: var(--color-light);
	padding: .5em 1em;
	border-radius: 50px;
	background-color: transparent;
	cursor: pointer;
	margin: 0 0.5em;
	transition: background-color 0.3s ease, color 0.3s ease;
}
.pagination-controls button:hover {
	background-color: var(--color-light);
	color: var(--color-dark);
}
.pagination-controls button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}